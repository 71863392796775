export default {
  headers: [
    { property: "name", numeric: false, label: "Game" },
    { property: "", numeric: false, label: "", disableSort: true },
    { property: "rank.overall", numeric: true, label: "BGGRank" },
    { property: "rating.average", numeric: true, label: "Rating" },
    { property: "weight.average", numeric: true, label: "Difficulty" },
    { property: "playTime.min", numeric: true, label: "Playtime (Min)" },
    { property: "playTime.max", numeric: true, label: "Playtime (Max)" },
    { property: "players.age.min", numeric: true, label: "Age (Min)" },
    {
      property: "players.age.recommended",
      numeric: true,
      label: "Age (Recommended)",
    },
    {
      property: "categories",
      numeric: false,
      label: "Categories",
      disableSort: true,
    },
    {
      property: "mechanics",
      numeric: false,
      label: "Mechanics",
      disableSort: true,
    },
    { property: "yearPublished", numeric: true, label: "Year" },
  ],
};
