import { Table, TableContainer } from "@mui/material";
import GameHeader from "./GameHeader/GameHeader";
import GameContent from "./GameContent/GameContent";

const GameList = () => {
  return (
    <TableContainer>
      <Table stickyHeader aria-label="sticky table">
        <GameHeader />
        <GameContent />
      </Table>
    </TableContainer>
  );
};

export default GameList;
