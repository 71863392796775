
import './App.css';
import TableTopPage from './TableTopPage/TableTopPage';

function App() {
  return (
    <div className="App">
      <TableTopPage></TableTopPage>
    </div>
  );
}

export default App;
