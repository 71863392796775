import GameHeaderConst from "./GameHeaderConst";
import { TableCell, TableHead, TableRow, TableSortLabel } from "@mui/material";
import { useContext, useState } from "react";
import "./GameHeader.scss";
import { GameContext } from "../../GameContext/GameContext";
import { stableSort, getComparator } from "../../Helper/sort.helper";

const GameHeader = () => {
  const [orderBy, setOrderBy] = useState("yearPublished");
  const [order, setOrder] = useState("desc");
  const { gameInfo, setFilteredGameInfo } = useContext(GameContext);

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    setFilteredGameInfo(
      stableSort(gameInfo, getComparator(isAsc ? "desc" : "asc", property))
    );
  };

  return (
    <TableHead className="game-list-headers">
      <TableRow>
        <TableCell className="game-list-header" />
        {GameHeaderConst.headers.map(({ label, property, disableSort }) => {
          return (
            <TableCell
              className="game-list-header"
              key={property}
              sortDirection={orderBy === property ? order : false}
            >
              {disableSort ? (
                <div>{label}</div>
              ) : (
                <TableSortLabel
                  active={orderBy === property}
                  direction={orderBy === property ? order : "asc"}
                  onClick={() => handleRequestSort(property)}
                >
                  {label}
                </TableSortLabel>
              )}
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};

export default GameHeader;
