import { useContext, useState } from "react";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { GameContext } from "../../GameContext/GameContext";
import {
  Button,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  Collapse,
} from "@mui/material";
import "./GameContent.scss";

const GameContent = () => {
  const { filteredGameInfo } = useContext(GameContext);

  const ExpandableTableRow = ({
    name,
    description,
    dataValues,
    boxImageUrl,
  }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    return (
      <>
        <TableRow>
          <TableCell>
            <IconButton
              color="primary" // Customize the button's color
              onClick={() => setIsExpanded(!isExpanded)}
            >
              {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          </TableCell>
          <TableCell>
            <div className="game-image-container">
              <img
                alt={`${name} box`}
                className="game-image"
                src={boxImageUrl}
                loading="lazy"
              />
            </div>
          </TableCell>
          {dataValues.map(({ value, id }) => {
            return <TableCell key={`${name}_${id}`}>{value}</TableCell>;
          })}
        </TableRow>
        <TableRow key={`${name} description`}>
          <TableCell colSpan={12} style={{ paddingTop: 0, paddingBottom: 0 }}>
            <Collapse in={isExpanded}>
              <div className="game-description-container">
                <p
                  className="game-description"
                  dangerouslySetInnerHTML={{ __html: description }}
                />
                <Button
                  className="game-desc-link"
                  variant="contained"
                  target="_blank"
                  href={`https://www.youtube.com/results?search_query=${name} board game`}
                >
                  View on Youtube
                </Button>
              </div>
            </Collapse>
          </TableCell>
        </TableRow>
      </>
    );
  };

  return (
    <TableBody className="game-content">
      {filteredGameInfo.map(
        ({
          categories,
          description,
          images,
          mechanics,
          name,
          playTime,
          players,
          rank,
          rating,
          weight,
          yearPublished,
        }) => {
          const dataValues = [
            { id: 1, value: name || "N/A" },
            { id: 2, value: rank?.overall || "N/A" },
            { id: 3, value: Math.round(rating?.average * 10) / 10 || "N/A" },
            { id: 4, value: Math.round(weight?.average * 10) / 10 || "N/A" },
            { id: 5, value: playTime.min || "N/A" },
            { id: 6, value: playTime.max || "N/A" },
            { id: 7, value: players.age.min || "N/A" },
            { id: 8, value: players.age.recommended || "N/A" },
            { id: 9, value: categories?.join(", ").trim() || "N/A" },
            { id: 10, value: mechanics?.join(", ").trim() || "N/A" },
            { id: 11, value: yearPublished || "N/A" },
          ];
          const boxImageUrl = images[0].url;

          return (
            <ExpandableTableRow
              key={name}
              name={name}
              description={description}
              dataValues={dataValues}
              boxImageUrl={boxImageUrl}
            />
          );
        }
      )}
    </TableBody>
  );
};

export default GameContent;
