import { GameContextProvider } from "./GameContext/GameContext";
import Filter from "./Filter/Filter";
import GameList from "./GameList/GameList";
import "./TableTopPage.scss";

const TableTopPage = () => {
  return (
    <div className="table-top-page">
      <GameContextProvider>
        <Filter />
        <GameList />
      </GameContextProvider>
    </div>
  );
};

export default TableTopPage;
