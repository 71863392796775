import SearchIcon from "@mui/icons-material/Search";
import { useContext } from "react";
import { GameContext } from "../GameContext/GameContext";
import { InputAdornment, TextField } from "@mui/material";
import { FormControl } from "@mui/base";

const Filter = () => {
  const { gameInfo, setFilteredGameInfo } = useContext(GameContext);
  const handleChange = (e) => {
    const filterText = e.target.value.toLowerCase();

    setFilteredGameInfo(
      gameInfo.filter(({ name, categories, mechanics }) => {
        const getTokens = (inputString) => {
          return inputString
            .toLowerCase()
            .split(/(\s)/)
            .filter((token) => token !== " " && token !== "");
        };

        const hasPartialMatches = (firstArray, secondArray) => {
          // Iterate over each token in the second array
          return secondArray.every((secondToken) => {
            // Check if there is any token in the first array that includes the current token
            return firstArray.some((firstToken) =>
              firstToken.includes(secondToken)
            );
          });
        };

        const tokens = getTokens(name)
          .concat(getTokens(categories.flat().toString()))
          .concat(getTokens(mechanics.flat().toString()));

        return hasPartialMatches(tokens, getTokens(filterText));
      })
    );
  };

  return (
    <FormControl variant="standard">
      <TextField
        fullWidth
        onChange={handleChange}
        type="search"
        variant="standard"
        size="medium"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon fontSize="medium" htmlColor="black" />
            </InputAdornment>
          ),
        }}
      />
    </FormControl>
  );
};

export default Filter;
