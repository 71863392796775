import { createContext } from "react";
import { useEffect, useState } from "react";
import { getComparator, stableSort } from "../Helper/sort.helper";
export const GameContext = createContext();

export const GameContextProvider = ({ children }) => {
  const [gameInfo, setGameInfo] = useState([]);
  const [filteredGameInfo, setFilteredGameInfo] = useState([]);

  useEffect(() => {
    fetch("gameData/gameData.json")
      .then((response) => {
        return response.json();
      })
      .then((response) => {
        setGameInfo(response);
        setFilteredGameInfo(
          stableSort(response, getComparator("desc", "yearPublished"))
        );
      });
  }, []);

  return (
    <GameContext.Provider
      value={{ gameInfo, filteredGameInfo, setFilteredGameInfo }}
    >
      {children}
    </GameContext.Provider>
  );
};
